import { differenceInHours, differenceInMinutes, isFuture } from 'date-fns';
import React from 'react';
import useRerenderInterval from '~/hooks/useRerenderInterval';
import { RailsItemLabel } from '~/typings/services/rails/item';

interface Props {
  auctionEndedAt?: string | undefined;
  auctionLiveCountdown?: boolean;
  label: RailsItemLabel;
  preferIcon?: boolean;
  variant?: 'contained' | 'text';
}

interface LabelIconProps {
  label: RailsItemLabel;
}

const ItemLabelIcon: React.FC<LabelIconProps> = ({ label }) => {
  return (
    <img
      src={label.small_url ? label.small_url + '?v=2' : undefined}
      alt={label.text}
      className="inline-block h-5 w-5"
    />
  );
};

export const ItemLabelPill: React.FC<Props> = ({
  auctionEndedAt,
  auctionLiveCountdown,
  label,
  preferIcon,
  variant = 'contained',
}) => {
  let content: React.ReactNode;

  if (auctionEndedAt) {
    if (auctionLiveCountdown) {
      content = (
        <>
          {preferIcon && label.small_url && <ItemLabelIcon label={label} />}
          <AuctionLiveCountdownText
            label={label}
            auctionEndedAt={auctionEndedAt}
            preferIcon={preferIcon}
          />
        </>
      );
    } else {
      content = (
        <>
          {preferIcon && label.small_url && <ItemLabelIcon label={label} />}
          <AuctionEndingSoonText
            label={label}
            auctionEndedAt={auctionEndedAt}
            preferIcon={preferIcon}
          />
        </>
      );
    }
  } else if (preferIcon && label.small_url) {
    content = <ItemLabelIcon label={label} />;
  } else {
    content = label.text;
  }

  if (variant === 'contained') {
    return (
      <div
        className="inline-flex items-center gap-2 self-center truncate whitespace-nowrap rounded-full px-2 py-0.5 text-xs font-semibold"
        style={{
          backgroundColor: label.color,
          color: label.text_color,
        }}
      >
        {content}
      </div>
    );
  } else {
    return (
      <div
        className="inline-flex items-center gap-2 self-center truncate whitespace-nowrap text-xs font-semibold"
        style={{
          color: label.color,
        }}
      >
        {content}
      </div>
    );
  }
};

interface AuctionLabelProps {
  auctionEndedAt?: string;
  label: RailsItemLabel;
  preferIcon?: boolean;
}

const AuctionLiveCountdownText: React.FC<AuctionLabelProps> = ({
  auctionEndedAt,
  label,
  preferIcon,
}) => {
  let text: React.ReactNode = preferIcon ? '' : label.text;

  let auctionIsActive = false;
  if (auctionEndedAt && isFuture(new Date(auctionEndedAt))) {
    auctionIsActive = true;

    const date = new Date(auctionEndedAt);

    const diffHours = differenceInHours(date, new Date());
    const diffMinutes = differenceInMinutes(date, new Date());

    if (diffHours < 24) {
      if (diffHours > 0) {
        if (!preferIcon) {
          text = 'AUCTION: ';
        }

        text += `${diffHours}h ${diffMinutes % 60}m left!`;
      } else if (diffMinutes > 0) {
        if (!preferIcon) {
          text = 'AUCTION: ';
        }

        text += `${diffMinutes % 60}m left!`;
      } else {
        text = label.text;
      }
    }
  }

  useRerenderInterval(auctionIsActive ? 1000 : null);

  if (!text) {
    return null;
  }

  return <>{text}</>;
};

const AuctionEndingSoonText: React.FC<AuctionLabelProps> = ({
  auctionEndedAt,
  label,
  preferIcon,
}) => {
  let text: React.ReactNode = label.text;

  if (auctionEndedAt && isFuture(new Date(auctionEndedAt))) {
    const date = new Date(auctionEndedAt);

    const diffHours = differenceInHours(date, new Date());

    if (diffHours < 24 && diffHours >= 0) {
      if (preferIcon) {
        text = 'ENDS SOON';
      } else {
        text = 'AUCTION: ENDS SOON';
      }
    }
  }

  if (!text) {
    return null;
  }

  return <>{text}</>;
};

export default ItemLabelPill;
