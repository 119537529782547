'use client';

import createSvg from './createSvg';

export default createSvg<{ active?: boolean }>(
  ({ active, height, width, fill = '#4a4a4a', ...props }) => (
    <svg
      height={height || 20}
      width={width || 20}
      {...props}
      viewBox="0 0 22 22"
    >
      {!active && (
        <path
          id="outline"
          fill={fill}
          d="M16.5 3c-1.74 0-3.4.8-4.5 2.1C10.9 3.8 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54l1.45 1.3 1.45-1.3C18.6 15.34 22 12.27 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.25 4 11.4 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04 1 3.57 2.36h1.87C13.46 6 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.9-3.14 5.74-7.9 10.05z"
        />
      )}
      {active && (
        <path
          id="fill"
          fill="#02C874"
          d="M16.5 3c-1.74 0-3.4.8-4.5 2.1C10.9 3.8 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54l1.45 1.3 1.45-1.3C18.6 15.34 22 12.27 22 8.5 22 5.42 19.58 3 16.5 3z"
        />
      )}
    </svg>
  ),
);
