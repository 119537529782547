import { useMemo } from 'react';
import { qsUpdateString } from 'utils';
import { AnalyticsSource } from '~/services/analytics/AnalyticsInterfaces';
import { RailsCartItemItem } from '~/typings/services/rails/cart';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';
import { RailsSwapDetail } from '~/typings/services/rails/swaps';
import extractPathname from '~/utils/extractPathname';

export interface ItemDetailsLinkParams {
  item?:
    | RailsItem
    | RailsThumbnailItem
    | RailsCartItemItem
    | RailsSwapDetail['item'];
  itemId?: number | string;
  src?: AnalyticsSource;
  url?: string;

  // Use this if you want to open the Published modal on the destination page
  justPublished?: boolean;
}

export default function useItemDetailsLink({
  item,
  itemId,
  justPublished,
  src,
  url,
}: ItemDetailsLinkParams) {
  return useMemo(() => {
    const query = qsUpdateString([
      url?.split('?')[1],
      {
        src,
        published: justPublished ? 1 : undefined,
      },
    ]);

    let itemUrl: string | undefined;

    if (item?.state === 'draft') {
      itemUrl = `/items/${item.id}`;
    } else if (url) {
      if (url.startsWith('http')) {
        itemUrl = extractPathname(url) + query;
      } else {
        itemUrl = url + query;
      }
    } else if (item?.url) {
      itemUrl = extractPathname(item.url) + query;
    } else if (item?.id || itemId) {
      itemUrl = `/gear/${item?.id || itemId}` + query;
    }

    if (!itemUrl) {
      throw new Error('No URL-identifiable property defined');
    }

    return itemUrl;
  }, [item?.id, item?.state, item?.url, itemId, src, url]);
}
