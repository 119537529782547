import React from 'react';
import { Avatar } from '~/components/Avatar';
import LockerLink from '~/components/Links/LockerLink';
import SidelineProBadgesEmblems from '~/components/SidelinePro/SidelineProBadgesEmblems';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';

interface Props {
  item: RailsThumbnailItem | RailsItem;
}

const UsernameAvatarRow: React.FC<Props> = ({ item }) => {
  const username = item.seller.username;
  const cx =
    'text-xs text-white font-semibold -m-2 p-2 overflow-hidden hover:underline hover:text-white';

  if (item.seller.avatar_url) {
    return (
      <LockerLink
        username={username}
        className={`${cx} flex items-center gap-2`}
      >
        <Avatar size="small" avatarUrl={item.seller.avatar_url} />
        <span className="overflow-ellipsis whitespace-nowrap">{username}</span>

        <SidelineProBadgesEmblems emblems={item.seller.emblems} />
      </LockerLink>
    );
  }

  return (
    <LockerLink
      username={username}
      className={`${cx} flex gap-2 overflow-ellipsis whitespace-nowrap`}
    >
      {username}

      <SidelineProBadgesEmblems emblems={item.seller.emblems} />
    </LockerLink>
  );
};

export default UsernameAvatarRow;
