import { clsx } from 'clsx';

export function SidelineProBadge({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 87 48"
      className={clsx('inline-block', className)}
      style={{
        height: '1.5em',
      }}
    >
      <rect width="86.6" height="47.8" fill="#02C874" rx="13.2" />
      <path
        fill="#fff"
        d="M25.4 25.9h-5v-3.2h5c.8 0 1.4 0 1.9-.3s.8-.7 1-1.1c.3-.5.4-1 .4-1.6a4 4 0 0 0-.3-1.6c-.3-.5-.6-1-1.1-1.2-.5-.3-1.1-.5-1.9-.5h-3.6V33h-4V13.2h7.6c1.5 0 2.9.3 4 .9a6 6 0 0 1 3.4 5.6 6 6 0 0 1-.9 3.3c-.6 1-1.4 1.6-2.5 2.1s-2.5.8-4 .8Zm10.2-12.7h7.3c1.5 0 2.8.3 4 .7 1 .5 1.8 1.1 2.4 2 .6.9.9 2 .9 3.3 0 1-.2 2-.5 2.7a5 5 0 0 1-1.5 1.9c-.7.5-1.4.9-2.3 1.1l-1.3.7h-6.4v-3.1H43c.7 0 1.3-.2 1.7-.4.5-.3.8-.6 1-1 .3-.5.4-1 .4-1.6 0-.7 0-1.2-.3-1.7-.2-.4-.6-.8-1-1a4 4 0 0 0-1.9-.4h-3.3V33h-4V13.2Zm11 19.7L42 24.1h4.3l4.5 8.6v.2h-4.3Zm22.9-10.3v1c0 1.4-.2 2.8-.6 4-.4 1.2-1 2.2-1.7 3-.8.8-1.7 1.5-2.7 2-1 .4-2.1.6-3.4.6-1.2 0-2.3-.2-3.4-.7-1-.4-1.9-1-2.6-1.9a9 9 0 0 1-1.8-3c-.4-1.2-.6-2.6-.6-4v-1c0-1.5.2-2.8.6-4 .4-1.2 1-2.2 1.8-3 .7-.9 1.6-1.5 2.6-2 1-.4 2.2-.6 3.4-.6a7.4 7.4 0 0 1 6 2.6c.8.8 1.4 1.8 1.8 3 .4 1.2.6 2.5.6 4Zm-4.1 1v-1c0-1-.1-2-.3-2.8a6 6 0 0 0-.8-2 3.6 3.6 0 0 0-3.2-1.6c-.7 0-1.3.1-1.8.4-.6.3-1 .7-1.4 1.2-.3.6-.6 1.2-.8 2a12 12 0 0 0-.3 2.8v1c0 1 .1 1.9.3 2.7.2.8.5 1.5.8 2 .4.6.9 1 1.4 1.2a3.9 3.9 0 0 0 3.6 0c.6-.2 1-.6 1.4-1.2.3-.5.6-1.2.8-2 .2-.8.3-1.7.3-2.8Z"
      />
    </svg>
  );
}

export function SidelineProPlusBadge({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 54 26"
      className={clsx('inline-block', className)}
      style={{
        height: '1.5em',
      }}
    >
      <rect width="53.3" height="25.2" x=".3" y=".8" fill="#FCC916" rx="6.8" />
      <path
        fill="#4F3E00"
        d="M13.9 14.3h-2.6v-1.7h2.6c.4 0 .7 0 1-.2l.5-.5.2-.8c0-.3 0-.6-.2-.9l-.5-.6c-.3-.2-.6-.2-1-.2H12v8.5h-2V7.7h3.9c.8 0 1.5.2 2 .5a3 3 0 0 1 1.8 2.9c0 .6-.2 1.2-.5 1.7a3 3 0 0 1-1.3 1c-.5.3-1.2.5-2 .5ZM19 7.7H23c.8 0 1.5.1 2 .4.6.2 1 .6 1.3 1 .3.5.5 1 .5 1.7 0 .5-.1 1-.3 1.4-.2.4-.4.7-.8 1a4 4 0 0 1-1.1.6l-.7.3h-3.3v-1.6h2.4c.4 0 .7 0 1-.2l.5-.5.2-.9c0-.3 0-.6-.2-.8-.1-.2-.3-.4-.6-.5a2 2 0 0 0-.9-.2h-1.7v8.5h-2V7.7ZM24.8 18l-2.3-4.5h2.2l2.3 4.4h-2.2Zm11.8-5.3v.5c0 .7 0 1.4-.3 2-.2.6-.5 1.2-.9 1.6-.4.4-.8.8-1.3 1l-1.8.3c-.6 0-1.2 0-1.7-.3a4 4 0 0 1-1.4-1c-.4-.4-.7-1-1-1.6l-.2-2v-.5c0-.8 0-1.5.3-2.1.2-.6.5-1.1.9-1.6l1.3-1 1.8-.3a3.8 3.8 0 0 1 3.1 1.3l1 1.6.2 2Zm-2 .5v-.5l-.2-1.5a3 3 0 0 0-.5-1 1.9 1.9 0 0 0-1.6-.8 1.9 1.9 0 0 0-1.6.8l-.5 1-.1 1.5v.5l.1 1.4.5 1c.2.3.4.5.7.6.2.2.6.3 1 .3.3 0 .6-.1.8-.3.3 0 .6-.3.7-.6l.5-1 .1-1.4Zm10-.9V14h-7v-1.8h7ZM42 9.5v7.4h-2V9.5h2Z"
      />
    </svg>
  );
}
