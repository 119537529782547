import { clsx } from 'clsx';
import * as React from 'react';
import Image from '~/components/Image';
import DEFAULT_AVATAR from '../public/static/missing-avatar.png';

type AvatarProps = Omit<React.ComponentProps<typeof Image>, 'src'> & {
  avatarUrl?: string | null;
  size?: 'small' | 'medium' | 'large' | 'xl' | '2xl' | 'unspecified';
};

export const Avatar: React.FC<AvatarProps> = ({
  avatarUrl,
  className,
  size,
  ...props
}) => (
  <Image
    className={clsx(
      'border-grey-200 max-w-none flex-shrink-0 rounded-full border-2 object-cover',
      {
        'h-6 w-6': size === 'small', // 24px
        'h-8 w-8': size === 'medium', // 32px
        'h-10 w-10': !size, // 40px
        'h-14 w-14': size === 'large', // 56px
        'h-24 w-24': size === 'xl', // 96px
        'h-28 w-28': size === '2xl', // 112px
      },
      className,
    )}
    alt="avatar"
    {...props}
    src={avatarUrl || DEFAULT_AVATAR}
  />
);
