import { PrinterIcon } from '@heroicons/react/24/outline';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { Message } from 'ui';
import {
  addItemToSale,
  deleteDiscardDraft,
  itemRemoveGmc,
  postPublishItem,
  postTakeDownItem,
  removeItemFromSale,
  unreportItem,
} from '~/api/item';
import ErrorMessage from '~/components/Form/ErrorMessage';
import ListFlowLink from '~/components/Links/ListFlowLink';
import NewTabLink from '~/components/Links/NewTabLink';
import SwapsInboxLink from '~/components/Links/SwapsInboxLink';
import { useModals } from '~/contexts/ModalsContext';
import { useSale } from '~/contexts/SaleContext';
import { useSession } from '~/contexts/SessionContext';
import useBumpItem from '~/hooks/useBumpItem';
import useCopyImageToClipboard from '~/hooks/useCopyImageToClipboard';
import { NegativeFeedbackIcon } from '~/public/static/svg/FeedbackIcons';
import {
  BumpIcon,
  DuplicateIcon,
  EditIcon,
  PriceDropIcon,
  PublishIcon,
  RemoveIcon,
  SaleTagIcon,
  StatsIcon,
  SwapsIcon,
  UnreportIcon,
} from '~/public/static/svg/ItemActionIcons';
import { RailsFacetItemMeta } from '~/typings/services/rails/facets';
import {
  RailsItem,
  RailsItemAction,
  RailsThumbnailItem,
} from '~/typings/services/rails/item';

const actionOrder: RailsItemAction[] = [
  'bump',
  'edit',
  'join_sale',
  'leave_sale',
  'price_drop',
  'publish',
  'view_swap',
  'view_swaps',
  'duplicate',
  'discard_draft',
  'remove',
  'take_down',
  'unreport',
  'remove_gmc',
];

interface Props {
  buttonClassName?: string;
  messageClassName?: string;
  item: RailsThumbnailItem | RailsItem;
  meta?: RailsFacetItemMeta;
  onInvalidate?: () => void;
  onViewQualityScore?: () => void;
  separator?: React.ReactNode;
}

const ItemThumbnailOverlayActions: React.FC<Props> = ({
  buttonClassName,
  messageClassName,
  item,
  meta,
  onInvalidate,
  onViewQualityScore,
  separator,
}) => {
  const session = useSession();
  const modals = useModals();
  const queryClient = useQueryClient();
  const buttonClasses = `rounded-sm py-2 px-2 flex flex-shrink-0 items-center font-semibold text-sm text-left transition duration-200 disabled:opacity-50 ${
    buttonClassName || ''
  }`;
  const iconClasses = 'mr-2 flex-shrink-0';

  const { sale } = useSale();

  const hasJoinSale = meta?.actions.includes('join_sale');
  const hasTakeDown = meta?.actions.includes('take_down');

  const {
    onCopy: copyLargeUrl,
    success: imageCopiedToClipboard,
    error: errorCopyingImage,
    loading: isCopyingImage,
  } = useCopyImageToClipboard(
    item.primary_image?.large_url || item.images[0]?.large_url,
  );

  const {
    mutate: handleBump,
    isLoading: isBumping,
    error: bumpError,
  } = useBumpItem(item.id, onInvalidate);

  const mutationOptions: UseMutationOptions = {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        exact: false,
        predicate: query => query.queryHash.includes(String(item.id)),
      });
      await onInvalidate?.();
    },
  };

  const availableActions = useMemo(() => {
    if (!meta) {
      return [];
    }

    return actionOrder.filter(
      function (this: RailsItemAction[], action: RailsItemAction) {
        return this.includes(action);
      },
      meta?.actions,
    );
  }, [meta?.actions]);

  const {
    error: takeDownError,
    status: takeDownStatus,
    mutate: takeDownItem,
  } = useMutation(() => postTakeDownItem(item.id), mutationOptions);

  const {
    status: publishStatus,
    mutate: publishItem,
    error: publishError,
  } = useMutation(() => postPublishItem(item.id), mutationOptions);

  const {
    mutate: unreport,
    isLoading: isUnreporting,
    error: unreportError,
    isSuccess: isUnreported,
  } = useMutation(() => unreportItem(item.id), mutationOptions);

  const {
    mutate: removeGmc,
    isLoading: isRemovingFromGmc,
    error: removeGmcError,
    isSuccess: isRemovedFromGmc,
  } = useMutation(() => itemRemoveGmc(item.id), mutationOptions);

  const { mutate: discardDraft } = useMutation(
    () => deleteDiscardDraft(item.id),
    mutationOptions,
  );

  const {
    mutate: toggleSale,
    error: saleToggleError,
    isLoading: isTogglingSale,
  } = useMutation(
    () =>
      hasJoinSale
        ? addItemToSale(item.id, sale?.id!)
        : removeItemFromSale(item.id, sale?.id!),
    {
      onSuccess: onInvalidate,
    },
  );

  const handleClickPriceDrop: React.MouseEventHandler = e => {
    e.preventDefault();

    modals.openModal('priceDrop', {
      item,
      onSuccessfulSubmit: onInvalidate,
    });
  };

  const error =
    publishError ||
    takeDownError ||
    bumpError ||
    saleToggleError ||
    unreportError ||
    errorCopyingImage;

  return (
    <>
      {error && (
        <ErrorMessage
          className={messageClassName}
          errors={error}
          style={{
            whiteSpace: 'break-spaces',
          }}
        />
      )}

      {isUnreported && (
        <Message
          className={messageClassName}
          success
          style={{
            whiteSpace: 'break-spaces',
          }}
        >
          Item unreported
        </Message>
      )}
      {isRemovedFromGmc && (
        <Message
          className={messageClassName}
          success
          style={{
            whiteSpace: 'break-spaces',
          }}
        >
          Removed from GMC
        </Message>
      )}
      {imageCopiedToClipboard && (
        <Message
          className={messageClassName}
          success
          style={{
            whiteSpace: 'break-spaces',
          }}
        >
          Copied image to clipboard
        </Message>
      )}
      {takeDownStatus === 'success' && (
        <Message
          className={messageClassName}
          success
          style={{
            whiteSpace: 'break-spaces',
          }}
        >
          Item has been taken down
        </Message>
      )}

      {hasTakeDown && meta?.quality_score && (
        <>
          <button
            key="quality-score"
            id="quality-score"
            className={buttonClasses}
            onClick={onViewQualityScore}
          >
            <StatsIcon height={16} width={16} className={iconClasses} /> Quality
            Score
          </button>
          {separator}
        </>
      )}

      {session?.decodedToken?.org_id && (
        <NewTabLink
          href={`/trade-in_listing_qr?item_id=${item.id}`}
          className={buttonClasses}
        >
          <PrinterIcon height={16} width={16} className={iconClasses} /> Print
          Warehouse Label
        </NewTabLink>
      )}

      {availableActions.map((action, i) => {
        let renderedAction: React.ReactNode;

        switch (action) {
          case 'edit':
            renderedAction = (
              <ListFlowLink
                key={action}
                id={action}
                itemId={item.id}
                className={buttonClasses}
              >
                <EditIcon
                  height={16}
                  width={16}
                  fill="currentColor"
                  className={iconClasses}
                />{' '}
                Edit
              </ListFlowLink>
            );
            break;

          case 'publish':
            renderedAction = (
              <button
                key={action}
                id={action}
                className={buttonClasses}
                onClick={() => publishItem()}
                disabled={publishStatus === 'loading'}
              >
                <PublishIcon
                  height={16}
                  width={16}
                  fill="currentColor"
                  className={iconClasses}
                />{' '}
                Publish
              </button>
            );
            break;

          case 'bump':
            renderedAction = (
              <button
                key={action}
                id={action}
                className={buttonClasses}
                onClick={() => handleBump()}
                disabled={isBumping}
              >
                <BumpIcon
                  height={16}
                  width={16}
                  fill="currentColor"
                  className={iconClasses}
                />{' '}
                Bump
              </button>
            );
            break;

          case 'view_swaps':
            renderedAction = (
              <SwapsInboxLink
                page="item"
                itemId={item.id}
                className={`flex-shrink-0 ${buttonClasses}`}
              >
                <SwapsIcon
                  height={16}
                  width={16}
                  fill="currentColor"
                  className={iconClasses}
                />{' '}
                View Swaps
              </SwapsInboxLink>
            );
            break;

          case 'duplicate':
            renderedAction = (
              <ListFlowLink
                src="item"
                duplicateId={item.id}
                className={`flex-shrink-0 ${buttonClasses}`}
              >
                <DuplicateIcon
                  height={16}
                  width={16}
                  fill="currentColor"
                  className={iconClasses}
                />{' '}
                Duplicate
              </ListFlowLink>
            );
            break;

          case 'remove':
            renderedAction = (
              <button
                key={action}
                id={action}
                className={buttonClasses}
                onClick={() =>
                  modals.openModal('removeReport', {
                    item,
                    onSuccessfulSubmit: onInvalidate,
                    type: 'removeItem',
                  })
                }
              >
                <RemoveIcon
                  height={16}
                  width={16}
                  fill="currentColor"
                  className={iconClasses}
                />{' '}
                Remove
              </button>
            );
            break;

          case 'discard_draft':
            renderedAction = (
              <button
                key={action}
                id={action}
                className={`${buttonClasses} text-red-500`}
                onClick={() => discardDraft()}
              >
                <RemoveIcon
                  height={16}
                  width={16}
                  fill="currentColor"
                  className={iconClasses}
                />{' '}
                Discard Draft
              </button>
            );
            break;

          case 'take_down':
            // Kinda odd, but "take down" includes the "Copy Image" action
            renderedAction = (
              <>
                <button
                  key={action}
                  id={action}
                  className={`${buttonClasses} text-red-500`}
                  onClick={() => takeDownItem()}
                  disabled={takeDownStatus === 'loading'}
                >
                  <RemoveIcon
                    height={16}
                    width={16}
                    fill="currentColor"
                    className={iconClasses}
                  />{' '}
                  Take Down
                </button>

                {separator}

                <button
                  key="copy-image"
                  id="copy-image"
                  className={buttonClasses}
                  onClick={() => copyLargeUrl()}
                  disabled={isCopyingImage}
                >
                  <DuplicateIcon
                    height={16}
                    width={16}
                    fill="currentColor"
                    className={iconClasses}
                  />{' '}
                  Copy Image
                </button>
              </>
            );
            break;

          case 'join_sale':
            renderedAction = (
              <button
                key={action}
                id={action}
                className={`${buttonClasses} text-green-500`}
                onClick={() => toggleSale()}
                disabled={isTogglingSale}
              >
                <SaleTagIcon
                  height={16}
                  width={16}
                  fill="currentColor"
                  className={iconClasses}
                />{' '}
                Add to Sale
              </button>
            );
            break;

          case 'leave_sale':
            renderedAction = (
              <button
                key={action}
                id={action}
                className={buttonClasses}
                onClick={() => toggleSale()}
                disabled={isTogglingSale}
              >
                <SaleTagIcon
                  height={16}
                  width={16}
                  fill="currentColor"
                  className={iconClasses}
                />{' '}
                Remove from Sale
              </button>
            );
            break;

          case 'price_drop':
            renderedAction = (
              <button
                key={action}
                id={action}
                className={buttonClasses}
                onClick={handleClickPriceDrop}
              >
                <PriceDropIcon
                  height={16}
                  width={16}
                  fill="currentColor"
                  className={iconClasses}
                />{' '}
                Price Drop
              </button>
            );
            break;

          case 'unreport':
            renderedAction = (
              <button
                key={action}
                id={action}
                className={buttonClasses}
                onClick={() => unreport()}
                disabled={isUnreporting}
              >
                <UnreportIcon height={16} width={16} className={iconClasses} />{' '}
                Unreport
              </button>
            );
            break;

          case 'remove_gmc':
            renderedAction = (
              <button
                key={action}
                id={action}
                className={buttonClasses}
                onClick={() => removeGmc()}
                disabled={isRemovingFromGmc}
              >
                <NegativeFeedbackIcon
                  height={16}
                  width={16}
                  fill="currentColor"
                  className={iconClasses}
                />{' '}
                Remove from GMC
              </button>
            );
            break;

          default:
            renderedAction = null;
            break;
        }

        return (
          <>
            {renderedAction}
            {i < availableActions.length - 1 && separator}
          </>
        );
      })}
    </>
  );
};

export default ItemThumbnailOverlayActions;
