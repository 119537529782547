import NextImage, { ImageProps, StaticImageData } from 'next/image';
import React from 'react';
import { qsUpdateString } from 'utils';

export interface FastlyImageOptimizerOptions {
  auto?: 'webp';
  'bg-color'?: string;
  blur?: string;
  brightness?: number | string;
  canvas?: string;
  contrast?: number | string;
  crop?: number | string;
  disable?: 'upscale';
  dpr?: number;
  enable?: 'upscale';
  fit?: 'bounds' | 'cover' | 'crop';
  format?:
    | 'gif'
    | 'png'
    | 'png8'
    | 'jpg'
    | 'jpeg'
    | 'bjpg'
    | 'pjpg'
    | 'webp'
    | 'webpll'
    | 'webply'
    | 'mp4';
  frame?: 1;
  height?: string | number;
  level?: string;
  optimize?: 'low' | 'medium' | 'high';
  orient?: 'r' | 'l' | 'h' | 'v' | 'hv' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  pad?: string;
  precrop?: FastlyImageOptimizerOptions['crop'];
  profile?: 'baseline' | 'main' | 'high';
  quality?: number | string;
  'resize-filter'?:
    | 'nearest'
    | 'bilinear'
    | 'linear'
    | 'cubic'
    | 'bicubic'
    | 'lanczos2'
    | 'lanczos'
    | 'lanczos3';
  saturation?: string | number;
  sharpen?: string;
  trim?: string; // top, right, bottom, left
  width?: number | string;
}

export function generateFastlyImageUrl(
  src: string,
  options?: FastlyImageOptimizerOptions | true,
  enabled = true,
) {
  if (!enabled) {
    return src;
  }

  try {
    const url = new URL(src);

    const stringifiedParams = qsUpdateString([
      url.search,
      typeof options === 'object' ? options : undefined,
    ]);

    if (url.hostname === 'images.sidelineswap.com') {
      url.hostname = 'edge.images.sidelineswap.com';
    }

    return url.origin + url.pathname + stringifiedParams;
  } catch (e) {
    return src;
  }
}

export interface CustomImageProps
  extends Omit<
    React.ImgHTMLAttributes<HTMLImageElement>,
    'src' | 'placeholder' | 'height' | 'width'
  > {
  height?: number;
  width?: number;
  useFastly?: boolean | FastlyImageOptimizerOptions;
  useFastlyBlurPlaceholder?: boolean | FastlyImageOptimizerOptions;
  useNextJSImage?: true | Omit<ImageProps, 'alt' | 'src'>;
  src: string | StaticImageData | undefined | null;
}

const Image = React.forwardRef<HTMLImageElement, CustomImageProps>(
  (
    {
      alt,
      className,
      src,
      useFastly,
      useFastlyBlurPlaceholder,
      useNextJSImage,
      ...props
    },
    ref,
  ) => {
    const imageUrl = typeof src === 'string' ? src : src?.src || src?.src;

    if (!imageUrl) {
      return (
        <img
          alt={alt ?? ''}
          loading="lazy"
          src={imageUrl}
          className={className}
          {...props}
        />
      );
    }

    if (useNextJSImage && typeof imageUrl === 'string') {
      return (
        <NextImage
          unoptimized
          loader={resolverProps => {
            if (useFastly) {
              let options: FastlyImageOptimizerOptions = {
                width: resolverProps.width,
              };

              if (typeof useFastly === 'object') {
                options = {
                  ...options,
                  ...useFastly,
                };
              }

              return generateFastlyImageUrl(resolverProps.src, options);
            }

            return imageUrl;
          }}
          loading="lazy"
          placeholder={useFastlyBlurPlaceholder ? 'blur' : undefined}
          blurDataURL={
            useFastlyBlurPlaceholder
              ? generateFastlyImageUrl(imageUrl, {
                  height: 16,
                  width: 16,
                  ...(typeof useFastlyBlurPlaceholder === 'object'
                    ? useFastlyBlurPlaceholder
                    : {}),
                })
              : undefined
          }
          src={imageUrl}
          className={className}
          {...props}
          {...(useNextJSImage === true ? {} : useNextJSImage)}
          alt={alt ?? ''}
        />
      );
    }

    const finalImageUrl = useFastly
      ? generateFastlyImageUrl(imageUrl, useFastly)
      : imageUrl;

    return (
      <img
        alt={alt ?? ''}
        loading="lazy"
        src={finalImageUrl}
        className={className}
        ref={ref}
        {...props}
      />
    );
  },
);

export default Image;
