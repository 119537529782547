'use client';

import React from 'react';
import { qsStringify } from 'utils';
import LoginLink from '~/components/Links/LoginLink';
import useChromelessContext from '~/contexts/ChromelessContext';
import { useSession } from '~/contexts/SessionContext';
import { AnalyticsSource } from '~/services/analytics/AnalyticsInterfaces';
import { fireInitiatedItem } from '~/services/analytics/events/item';

import { CustomLinkProps } from 'common-nextjs';

export type ListFlowLinkProps = CustomLinkProps & {
  duplicateId?: number;

  /** Open login modal if you're not authenticated */
  gatedListFlow?: boolean;

  itemId?: number;
  prefill?: Record<string, any>;
  src?: AnalyticsSource;
};

const ListFlowLink: React.FC<ListFlowLinkProps> = ({
  children,
  duplicateId,
  gatedListFlow,
  itemId,
  prefill,
  src,
  ...props
}) => {
  const session = useSession();
  const chromeless = useChromelessContext();

  const initiatedItem = () => {
    if (!itemId) {
      fireInitiatedItem({ src });
    }
  };

  if (chromeless.isChromeless) {
    if (typeof window === 'undefined') {
      return <button className={props.className}>{children}</button>;
    } else if (window['nativeBridge']?.viewListFlow) {
      return (
        <button
          className={props.className}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            initiatedItem();
            window['nativeBridge']?.viewListFlow();
          }}
        >
          {children}
        </button>
      );
    }
  }

  const params = {
    src,
    ...prefill,
    duplicate_id: duplicateId,
    prefill_data: prefill ? 1 : null,
  };

  if (gatedListFlow && !session.loggedIn) {
    return (
      <LoginLink
        destination={`/items/${itemId ? itemId : 'new'}${qsStringify(params)}`}
        message="Log in to start listing"
        {...props}
        rel="nofollow noindex"
        onClick={e => {
          props.onClick?.(e);
          initiatedItem();
        }}
      >
        {children}
      </LoginLink>
    );
  }

  return (
    <a
      href={`/items/${itemId ? itemId : 'new'}${qsStringify(params)}`}
      {...props}
      rel="nofollow noindex"
      onClick={e => {
        props.onClick?.(e);
        initiatedItem();
      }}
    >
      {children}
    </a>
  );
};

export default ListFlowLink;
