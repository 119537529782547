import urlLib from 'url';

export default function extractPathname(url?: string): string {
  if (!url) {
    return '';
  }

  const parsedUrl = urlLib.parse(url);
  return parsedUrl.pathname + (parsedUrl.search || '');
}
