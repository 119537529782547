import { RailsFacetItemMeta } from '~/typings/services/rails/facets';
import { useQueryClient } from '@tanstack/react-query';

export default function useThumbnailMeta(itemId: number) {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<RailsFacetItemMeta[]>(['item-meta'], {
    exact: false,
    type: 'active',
    predicate: q => (q.queryKey as [string, number[]])?.[1]?.includes?.(itemId),
  });

  return data?.find(meta => meta.id === itemId);
}
