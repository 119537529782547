import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import ItemThumbnailOverlayActions from '~/components/ItemThumbnail/ActionsOverlay/ItemThumbnailOverlayActions';
import SellerQualityScoreTooltip from '~/components/ItemThumbnail/ActionsOverlay/SellerQualityScoreTooltip';
import SimpleFade from '~/components/Transitions/SimpleFade';
import { NegativeFeedbackIcon } from '~/public/static/svg/FeedbackIcons';
import { RailsFacetItemMeta } from '~/typings/services/rails/facets';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';

interface Props {
  item: RailsThumbnailItem | RailsItem;
  meta?: RailsFacetItemMeta;
  onClose?: () => void;
  onInvalidate?: () => void;
  show: boolean;
}

const ItemThumbnailOverlay: React.FC<Props> = ({
  item,
  meta,
  onClose,
  onInvalidate,
  show,
}) => {
  const [index, setIndex] = useState(0);
  return (
    <SimpleFade
      show={show}
      className="bg-true-black absolute inset-0 z-20 overflow-y-auto rounded-lg bg-opacity-90 py-2 text-white"
    >
      <div className="mb-2 flex justify-between px-3">
        <SimpleFade
          as="button"
          show={index !== 0}
          className="h-4 w-4"
          onClick={() => setIndex(0)}
        >
          <ArrowLeftIcon className="h-4 w-4" />
        </SimpleFade>

        <button className="ml-auto" onClick={onClose}>
          <NegativeFeedbackIcon
            className="h-6 w-6 stroke-current"
            strokeWidth={2}
          />
        </button>
      </div>

      <SwipeableViews index={index} disabled slideClassName="px-3">
        <div className="mb-4 space-y-1 text-white">
          <ItemThumbnailOverlayActions
            buttonClassName="w-full hover:bg-grey-900 text-xs"
            item={item}
            meta={meta}
            onInvalidate={() => {
              onInvalidate?.();
              onClose?.();
            }}
            onViewQualityScore={() => setIndex(1)}
          />
        </div>

        {meta?.quality_score && (
          <div>
            <SellerQualityScoreTooltip score={meta?.quality_score} />
          </div>
        )}
      </SwipeableViews>
    </SimpleFade>
  );
};

export default ItemThumbnailOverlay;
