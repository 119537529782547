import { isSidelineProAvailable } from '~/constants/sidelineProFeatures';
import { useConfiguration } from '~/contexts/ConfigurationContext';
import { useSession } from '~/contexts/SessionContext';
import useUserConfiguration from '~/hooks/useUserConfiguration';

export function useIsSidelineProAvailable() {
  const session = useSession();
  const { data: config } = useConfiguration();

  return isSidelineProAvailable(config, session?.user?.username);
}

export function useIsSidelinePro() {
  const { data: userConfiguration } = useUserConfiguration();
  const available = useIsSidelineProAvailable();

  if (
    available &&
    (userConfiguration?.pro_seller || userConfiguration?.pro_plus_seller)
  ) {
    return userConfiguration?.pro_plus_seller || userConfiguration?.pro_seller;
  }

  return false;
}

export function useIsSidelineProPlus() {
  const { data: userConfiguration } = useUserConfiguration();
  const available = !!useIsSidelineProAvailable;

  if (!available || !userConfiguration?.pro_plus_seller) {
    return false;
  }

  return userConfiguration?.pro_plus_seller;
}
