import { useRouter } from 'next/router';
import { displayPrice } from 'utils';
import { useCallback } from 'react';
import localeToCurrency from '~/utils/localeToCurrency';

interface UsePriceOptions {
  dataSourceCurrency?: string;
  decimals?: boolean;
}

export default function usePrice(
  options: UsePriceOptions = { dataSourceCurrency: 'USD' },
) {
  const { locale } = useRouter();
  const currency = localeToCurrency(locale);

  const exchangeRate = { rate: 1 };

  return useCallback(
    (price?: number) => {
      let finalPrice = price;
      let priceChangedByExchangeRate = false;
      const useExchangeRate = currency !== options.dataSourceCurrency;

      if (useExchangeRate && price != null && exchangeRate?.rate) {
        finalPrice = +price * exchangeRate.rate;
        priceChangedByExchangeRate = true;
      }

      return {
        currency: priceChangedByExchangeRate
          ? currency
          : options.dataSourceCurrency,
        formattedPrice: displayPrice(finalPrice, options.decimals),
        price: finalPrice,
        nonUSDCurrency: currency !== 'USD' ? currency : false,
      };
    },
    [
      currency,
      exchangeRate?.rate,
      options.dataSourceCurrency,
      options.decimals,
    ],
  );
}
