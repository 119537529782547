import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useMobile } from 'common-nextjs';
import { useMemo } from 'react';
import { fetchWebConfiguration } from '~/api/configuration';
import { minutes30inMS } from '~/constants/times';
import { RailsWebConfiguration } from '~/typings/services/rails/configuration';

export function useConfiguration(): UseQueryResult<RailsWebConfiguration> {
  return useQuery(['configuration'], fetchWebConfiguration, {
    staleTime: minutes30inMS,
  });
}

export function useConfigurationProperty(
  property: 'ads' | 'gear_experts' | keyof RailsWebConfiguration,
) {
  const mobile = useMobile();
  const { data: configuration } = useConfiguration();

  return useMemo(() => {
    if (!configuration) {
      return false;
    }

    if (mobile) {
      if (property === 'ads') {
        return configuration.ads_mobile;
      }

      if (property === 'gear_experts') {
        return configuration.gear_experts_modal_mobile;
      }
    } else {
      if (property === 'ads') {
        return configuration.ads_desktop;
      }

      if (property === 'gear_experts') {
        return configuration.gear_experts_modal_desktop;
      }
    }

    return configuration[property];
  }, [mobile, configuration, property]);
}
