'use client';

import createSvg from '~/public/static/svg/createSvg';

export default createSvg(props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M3.85854 8.28113C3.44295 8.28113 3.10489 8.61923 3.10489 9.03482C3.10489 9.45054 3.44295 9.7886 3.85854 9.7886C4.27422 9.7886 4.61228 9.45054 4.61228 9.03482C4.61228 8.61923 4.27422 8.28113 3.85854 8.28113Z"
        fill="currentColor"
      />
      <path
        d="M9.45916 8.28113C9.04357 8.28113 8.70551 8.61923 8.70551 9.03482C8.70551 9.45054 9.04357 9.7886 9.45916 9.7886C9.87484 9.7886 10.2129 9.45054 10.2129 9.03482C10.2129 8.61923 9.87484 8.28113 9.45916 8.28113Z"
        fill="currentColor"
      />
      <path
        d="M11.3443 5.52583L11.341 4.56611C11.3403 4.34611 11.1618 4.16809 10.9417 4.16809H10.3426L10.0031 5.52583H11.3443Z"
        fill="currentColor"
      />
      <path
        d="M10.0138 2.69724C10.0401 2.57898 10.0112 2.45522 9.93546 2.3608C9.85962 2.26629 9.74504 2.2113 9.6239 2.2113H3.36444C3.17721 2.2113 3.01515 2.34128 2.97462 2.52403L2.89765 2.87021H2.04658C1.82606 2.87021 1.64723 3.049 1.64723 3.26952C1.64723 3.49005 1.82606 3.66888 2.04658 3.66888H2.72012L2.53159 4.51744H1.05826C0.837655 4.51744 0.658908 4.69631 0.658908 4.91679C0.658908 5.13736 0.837655 5.31615 1.05826 5.31615H2.35414L2.16553 6.16475H0.399353C0.178828 6.16475 0 6.34358 0 6.56411C0 6.78463 0.178828 6.96346 0.399353 6.96346H1.98804L1.65677 8.45416C1.63054 8.57239 1.65931 8.69618 1.73507 8.79065C1.81087 8.88515 1.92545 8.9401 2.04658 8.9401H2.30933C2.3585 8.1281 3.03446 7.48257 3.85854 7.48257C4.68266 7.48257 5.35862 8.1281 5.40783 8.9401H7.90997C7.94564 8.35222 8.30989 7.8517 8.82104 7.62019L10.0138 2.69724Z"
        fill="currentColor"
      />
      <path
        d="M11.9244 6.32031C11.8667 6.24031 11.7821 6.18576 11.6878 6.16467H9.84348L9.5137 7.48378C10.311 7.51142 10.9573 8.14305 11.0081 8.93444C11.1491 8.91053 11.2726 8.81162 11.3208 8.6664L11.9798 6.6797C12.02 6.55792 11.9995 6.42431 11.9244 6.32031Z"
        fill="currentColor"
      />
    </g>
  </svg>
));
