'use client';

import { EnvelopeIcon } from '@heroicons/react/24/outline';
import createSvg from './createSvg';

export const EditIcon = createSvg(({ height = 24, width = 24, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.86.86a.67.67 0 01.94 0l2.67 2.67c.26.26.26.68 0 .94L5.14 11.8c-.13.13-.3.2-.47.2H2a.67.67 0 01-.67-.67V8.67c0-.18.07-.35.2-.47L8.86.86zm-6.2 8.08v1.73H4.4L11.06 4 9.33 2.28 2.67 8.94zM1.33 14.67c0-.37.3-.67.67-.67h12a.67.67 0 010 1.33H2a.67.67 0 01-.67-.66z"
      fill="currentColor"
    />
  </svg>
));

export const BumpIcon = createSvg(
  ({ height = 24, width = 24, fill = '#d8d8d8', ...props }) => (
    <svg
      width={width || 24}
      height={height || 24}
      {...props}
      viewBox="0 0 20 22"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      >
        <path d="M5.81 6.205s.041-1.362.041 3.055c0 6.192 12.188 2.91 12.188 8.195 0 1.63.016 1.24.016 1.997" />
        <path d="M9.909 6L5.813 1.75 1.75 6" />
      </g>
    </svg>
  ),
);

export const PriceDropIcon = createSvg(
  ({ height = 24, width = 24, fill = '#d8d8d8', ...props }) => (
    <svg
      width={width || 24}
      height={height || 24}
      {...props}
      viewBox="0 0 12 22"
    >
      <path
        fill={fill}
        fillRule="nonzero"
        d="M4.275 12.56h2.309c1.286 0 1.966.419 1.966 1.696v.86c0 1.585-.87 1.753-2.378 1.72-.894 0-4.085-.137-5.507-.23v2.627c1.162.295 2.707.57 4.395.665V22h2.107v-2.07c2.69-.075 4.81-1.116 4.833-4.549v-1.522c0-1.478-.452-4.67-4.657-4.67H5.088c-1.317 0-1.638-.642-1.638-1.644v-.948c0-1.296 1.193-1.518 2.23-1.518 2.291 0 4.768.157 5.753.21V2.696c-1.345-.377-2.375-.578-4.266-.667V0H5.06v1.974C2.56 1.936 0 2.825 0 6.716V7.89c0 1.948.685 4.67 4.275 4.67z"
      />
    </svg>
  ),
);

export const SaleTagIcon = createSvg(
  ({ height = 24, width = 24, fill = '#d8d8d8', ...props }) => (
    <svg
      height={height || 20}
      width={width || 20}
      {...props}
      viewBox="0 0 20 20"
    >
      <path
        fill={fill}
        d="M19.41 9.58l-9-9C10.05.22 9.55 0 9 0H2C.9 0 0 .9 0 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM3.5 5C2.67 5 2 4.33 2 3.5S2.67 2 3.5 2 5 2.67 5 3.5 4.33 5 3.5 5z"
      />
    </svg>
  ),
);

export const RemoveIcon = createSvg(
  ({ height = 24, width = 24, fill = '#d8d8d8', ...props }) => (
    <svg
      width={width}
      height={height}
      {...props}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33 4c0-.37.3-.67.67-.67h12a.67.67 0 010 1.34H2A.67.67 0 011.33 4z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.67 2a.67.67 0 00-.67.67v.66h4v-.66A.67.67 0 009.33 2H6.67zm4.66 1.33v-.66a2 2 0 00-2-2H6.67a2 2 0 00-2 2v.66H3.33c-.36 0-.66.3-.66.67v9.33a2 2 0 002 2h6.66a2 2 0 002-2V4c0-.37-.3-.67-.66-.67h-1.34zM4 4.67v8.66a.67.67 0 00.67.67h6.66a.67.67 0 00.67-.67V4.67H4z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.67 6.67c.36 0 .66.3.66.66v4a.67.67 0 11-1.33 0v-4c0-.36.3-.66.67-.66zM9.33 6.67c.37 0 .67.3.67.66v4a.67.67 0 11-1.33 0v-4c0-.36.3-.66.66-.66z"
        fill="currentColor"
      />
    </svg>
  ),
);

export const SwapsIcon = createSvg(
  ({ height = 24, width = 24, fill, ...props }) => (
    <EnvelopeIcon height={height} width={width} {...props} />
  ),
);

export const DuplicateIcon = createSvg(
  ({ height = 24, width = 24, ...props }) => (
    <svg
      height={height || 24}
      width={width || 24}
      {...props}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33 6.67c-.36 0-.66.3-.66.66v6c0 .37.3.67.66.67h6c.37 0 .67-.3.67-.67v-6c0-.36-.3-.66-.67-.66h-6zm-2 .66c0-1.1.9-2 2-2h6a2 2 0 012 2v6a2 2 0 01-2 2h-6a2 2 0 01-2-2v-6z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.67 2a.67.67 0 00-.67.67v6a.67.67 0 00.67.66h.66a.67.67 0 010 1.34h-.66a2 2 0 01-2-2v-6a2 2 0 012-2h6a2 2 0 012 2v.66a.67.67 0 01-1.34 0v-.66A.67.67 0 008.67 2h-6z"
        fill="currentColor"
      />
    </svg>
  ),
);

export const PublishIcon = createSvg(
  ({ height = 24, width = 24, ...props }) => (
    <svg
      height={height || 24}
      width={width || 24}
      {...props}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9.33c.37 0 .67.3.67.67v2.67a.67.67 0 00.66.66h9.34a.67.67 0 00.66-.66V10a.67.67 0 011.34 0v2.67a2 2 0 01-2 2H3.33a2 2 0 01-2-2V10c0-.37.3-.67.67-.67zM7.53 1.53a.67.67 0 01.94 0l3.33 3.33a.67.67 0 11-.94.94L8 2.94 5.14 5.8a.67.67 0 11-.94-.94l3.33-3.33z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.33c.37 0 .67.3.67.67v8a.67.67 0 01-1.34 0V2c0-.37.3-.67.67-.67z"
        fill="currentColor"
      />
    </svg>
  ),
);

export const BundleIcon = createSvg(({ height = 24, width = 24, ...props }) => (
  <svg height={height || 24} width={width || 24} {...props} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.2 1.4c.189-.252.485-.4.8-.4h12c.315 0 .611.148.8.4l3 4c.13.173.2.384.2.6v14c0 .796-.316 1.559-.879 2.121-.562.563-1.325.879-2.121.879H5c-.796 0-1.559-.316-2.121-.879C2.316 21.56 2 20.796 2 20V6c0-.216.07-.427.2-.6l3-4zM6.5 3L4 6.333V20c0 .265.105.52.293.707.187.188.442.293.707.293h14c.265 0 .52-.105.707-.293.188-.187.293-.442.293-.707V6.333L17.5 3h-11z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 6c0-.552.448-1 1-1h18c.552 0 1 .448 1 1s-.448 1-1 1H3c-.552 0-1-.448-1-1zM8 9c.552 0 1 .448 1 1 0 .796.316 1.559.879 2.121.562.563 1.325.879 2.121.879s1.559-.316 2.121-.879C14.684 11.56 15 10.796 15 10c0-.552.448-1 1-1s1 .448 1 1c0 1.326-.527 2.598-1.464 3.536C14.598 14.473 13.326 15 12 15s-2.598-.527-3.536-1.464C7.527 12.598 7 11.326 7 10c0-.552.448-1 1-1z"
      clipRule="evenodd"
    />
  </svg>
));

export const StatsIcon = createSvg(({ height = 24, width = 24, ...props }) => (
  <svg
    height={height}
    width={width}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6c.37 0 .67.3.67.67v6.66a.67.67 0 01-1.34 0V6.67c0-.37.3-.67.67-.67zM8 2c.37 0 .67.3.67.67v10.66a.67.67 0 11-1.34 0V2.67c0-.37.3-.67.67-.67zM4 8.67c.37 0 .67.3.67.66v4a.67.67 0 11-1.34 0v-4c0-.36.3-.66.67-.66z"
      fill="currentColor"
    />
  </svg>
));

export const UnreportIcon = createSvg(
  ({ height = 24, width = 24, ...props }) => (
    <svg
      height={height}
      width={width}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g
        clipPath="url(#clip0)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      >
        <path d="M.67 2c.36 0 .66.3.66.67V6h3.34a.67.67 0 010 1.33h-4A.67.67 0 010 6.67v-4C0 2.3.3 2 .67 2z" />
        <path d="M7.07 1.4a6.67 6.67 0 11-5.36 8.82.67.67 0 111.26-.44 5.33 5.33 0 101.25-5.53l-3.1 2.9a.67.67 0 11-.91-.97l3.09-2.9A6.67 6.67 0 017.07 1.4z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
);
