import { Transition, TransitionEvents } from '@headlessui/react';
import React from 'react';

// Hard to type this until @headlessui/react gets their types reasonably consumable...
type Props = TransitionEvents &
  React.HTMLAttributes<any> & {
    as?: any;
    appear?: any;
    show: boolean;
  };

const SimpleFade: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}) => (
  <Transition
    as="div"
    {...props}
    enter="transition ease-in-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="opacity-100 transition ease-in-out duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition>
);

export default SimpleFade;
