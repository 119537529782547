import { clsx } from 'clsx';
import React from 'react';

interface Props {
  className?: string;
}

const SuperScriptedCurrency: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
}) => (
  <span className={clsx('text-2xs relative -top-1 left-0.5', className)}>
    {children}
  </span>
);

export default SuperScriptedCurrency;
