import { CustomLinkProps, useMobile } from 'common-nextjs';
import Link from 'next/link';
import React from 'react';
import useKnownCookie from '~/hooks/useKnownCookie';

interface Props extends CustomLinkProps {
  itemId?: string | number;
  page?: 'item';
  type?: string;
  scope?: string; // only used if `type` is provided
}

const SwapsInboxLink: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  itemId,
  page,
  type,
  scope,
  ...props
}) => {
  const [tab] = useKnownCookie('sls_swaps_inbox_tab');
  const mobile = useMobile();
  let href = '/swaps';

  if (type && scope) {
    href = `/swaps/${type}/${scope}`;
  } else if (type) {
    if (mobile) {
      href = `/swaps/${type}`;
    } else {
      href = `/swaps/${type}/all`;
    }
  } else if (itemId) {
    href = `/swaps/${tab || 'sell'}/search?q=${itemId}`;
  } else {
    if (mobile) {
      href = `/swaps/${tab || 'sell'}`;
    } else {
      href = `/swaps/${tab || 'sell'}/all`;
    }
  }

  return (
    <Link {...props} href={href}>
      {children}
    </Link>
  );
};

export default SwapsInboxLink;
