import {
  SidelineProBadge,
  SidelineProPlusBadge,
} from '~/components/SidelinePro/ProBadges';
import { RailsConfigEmblem } from '~/typings/services/rails/configuration';

export default function SidelineProBadgesEmblems({
  emblems,
}: {
  emblems: RailsConfigEmblem['slug'][];
}) {
  // const proAvailable = useIsSidelineProAvailable();
  // if (!proAvailable) {
  //   return null;
  // }

  return (
    <>
      {emblems.includes('pro_seller') && <SidelineProBadge />}
      {emblems.includes('pro_plus_seller') && <SidelineProPlusBadge />}
    </>
  );
}
