import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { RailsSellerQualityScore } from '~/typings/services/rails/facets';

interface Props {
  score: RailsSellerQualityScore;
}

const SellerQualityScoreTooltip: React.FC<Props> = ({ score }) => (
  <table className="text-xs">
    <tbody>
      {Object.entries(score).map(([key, value]) => {
        let visualValue;
        let title;

        if (typeof value === 'string' && key.endsWith('_at')) {
          const d = new Date(value);
          visualValue = formatDistanceToNow(d, {
            addSuffix: true,
            includeSeconds: true,
          });
          title = d.toLocaleString();
        } else {
          visualValue = value;
        }

        return (
          <tr key={key}>
            <td className="pb-1 pr-4 text-right font-semibold">{key}:</td>
            <td className="pb-1" title={title}>
              {visualValue}
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

export default SellerQualityScoreTooltip;
