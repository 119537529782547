import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postBumpItem, postV2BumpItem } from '~/api/item';
import { useModals } from '~/contexts/ModalsContext';
import { getKnownCookie } from '~/hooks/cookies';
import useBumpInfo from '~/hooks/useBumpInfo';
import { clickedBump } from '~/services/analytics/events/item';
import { useIsSidelineProAvailable } from './useIsSidelinePro';

export default function useBumpItem(itemId: number, onBumped?: () => void) {
  const modals = useModals();
  const queryClient = useQueryClient();
  const { data: bumpsInfo } = useBumpInfo();
  const proAvailable = useIsSidelineProAvailable();

  const mutation = useMutation(
    () => {
      if (proAvailable) {
        return postV2BumpItem(itemId);
      } else {
        return postBumpItem(itemId);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => {
            const key = query.queryKey[0];
            if (typeof key === 'string') {
              return ['item-meta'].includes(key);
            }

            return false;
          },
        });

        onBumped?.();
      },
    },
  );

  function handleBump() {
    clickedBump({ availableBumpCount: bumpsInfo?.available_bumps });

    if (proAvailable) {
      const hideBumpsModal = getKnownCookie(null, 'sls_hbm') === '1';
      if (!hideBumpsModal) {
        modals.openModal('proBumpsGuidance', {
          itemId,
          onSuccessfulBump: onBumped,
        });
        return;
      }
    } else if (bumpsInfo?.items_bumped === 0) {
      modals.openModal('bumpsGuidance', {
        itemId,
        onSuccessfulBump: onBumped,
      });
      return;
    } else if (bumpsInfo?.available_bumps === 0) {
      modals.openModal('bumpsPurchase', {
        onSuccessfulPurchase: onBumped,
      });
      return;
    }

    return mutation.mutate();
  }

  return { ...mutation, mutate: handleBump, mutateAsync: handleBump };
}
