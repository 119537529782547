'use client';

import Link from 'next/link';
import React from 'react';
import useItemDetailsLink, {
  ItemDetailsLinkParams,
} from '~/hooks/links/useItemDetailsLink';

import { CustomLinkProps } from 'common-nextjs';

type Props = CustomLinkProps & ItemDetailsLinkParams;

const ItemDetailsLink: React.FC<Props> = ({
  children,
  item,
  itemId,
  onClick,
  src,
  url,
  ...props
}) => {
  const itemUrl = useItemDetailsLink({
    item,
    itemId,
    src,
    url,
  });

  return (
    <Link
      {...props}
      href={itemUrl}
      onClick={e => {
        if (
          window.nativeBridge &&
          typeof window.nativeBridge.viewItem == 'function' &&
          (item?.id || itemId)
        ) {
          e.preventDefault();
          e.stopPropagation();

          try {
            window.nativeBridge.viewItem(item!.id! || itemId!);
          } catch (e) {}
        } else if (onClick) {
          onClick(e);
        }
      }}
    >
      {children}
    </Link>
  );
};

export default ItemDetailsLink;
