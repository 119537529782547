export default function localeToCurrency(locale?: string) {
  let currency: string;

  if (locale === 'en-CA') {
    currency = 'CAD';
  } else {
    currency = 'USD';
  }

  return currency;
}
