import { selectEnv } from 'common-nextjs';

// These are totally locally-driven feature flags.
// But it can be used as a starting point for a better
// backend-driven feature toggle system in the future.

// Hides the Purchase gift card actions.
export const FT_GIFT_CARD_PURCHASE_ENABLED = selectEnv(true, true);

// Displays a warning on the Gift Card page notifying the user that gift
// card purchases are unavailable.
export const FT_GIFT_CARD_PURCHASE_UNAVAILABLE_WARNING = selectEnv(
  false,
  false,
);

// Toggles the Gear Experts 45-second delayed popup
export const FT_GEAR_EXPERTS_POPUP = true;

// Uses a scheduling system. Requires FT_GEAR_EXPERTS_POPUP or FT_GEAR_EXPERTS_BANNER
// to be true. If FT_GEAR_EXPERTS_BANNER and USE_SCHEDULE is true but you're in the
// wrong time, it will not display.
export const FT_GEAR_EXPERTS_USE_SCHEDULE = true;

// Toggles the Gear Experts persistent banner on search/shop pages
export const FT_GEAR_EXPERTS_BANNER = true;

// Toggles the "automatic join modal" after 30 seconds
export const FT_JOIN_MODAL_POPUP = false;

// Toggles what the Gear Expert CTA should trigger.
// If it's Zendesk, it sets a cookie and opens the Zendesk widget.
// If it's Typeform, it opens the typeform survey.
export const FT_GEAR_EXPERT_CTA_MODE: 'typeform' | 'zendesk' = 'zendesk';

// Toggles the ZipPay widget on item pages.
// If you're toggling this, be sure to toggle the sls-react version as well.
export const FT_ZIP_PAY = true;

// Toggles Fastly Image Optimization globally
export const FT_FASTLY_IO = true;
