/**
 * Takes strings or objects to return a combined query string
 */
import { IStringifyOptions } from 'qs';
import { qsParse } from './qsParse';
import { qsStringify } from './qsStringify';

export function qsUpdateString(
  input: (string | null | undefined | Record<string, any> | URLSearchParams)[],
  options?: IStringifyOptions,
): string {
  const combined = input.reduce<Record<string, any>>((prev, cur) => {
    if (typeof cur === 'string') {
      return {
        ...prev,
        ...qsParse(cur),
      };
    } else {
      return {
        ...prev,
        ...cur,
      };
    }
  }, {});

  return qsStringify(combined, options);
}
