import { useState } from 'react';

export default function useCopyImageToClipboard(imageLargeUrl: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);

  async function onCopy() {
    // @ts-ignore
    if (typeof window.ClipboardItem === 'undefined') {
      setError('Browser is too old to use this functionality.');
      return;
    }

    try {
      setCopied(false);
      setError(null);
      setLoading(true);

      await copyImg(imageLargeUrl);

      setLoading(false);
      setCopied(true);
    } catch (err) {
      setError((err as Error).message);
      setLoading(false);
      setCopied(false);
    }
  }

  return {
    error,
    loading,
    onCopy,
    success: copied,
  };
}

function createImage(options) {
  options = options || {};
  const img = Image ? new Image() : document.createElement('img');
  if (options.src) {
    img.src = options.src;
  }
  return img;
}

async function convertToPng(imgBlob: Blob) {
  return new Promise((resolve, reject) => {
    const imageUrl = window.URL.createObjectURL(imgBlob);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      reject('Failed to create canvas');
      return;
    }
    const imageEl = createImage({ src: imageUrl });
    imageEl.onload = e => {
      // @ts-ignore
      const width = e.target.width;
      // @ts-ignore
      const height = e.target.height;

      canvas.width = width;
      canvas.height = height;

      // @ts-ignore
      ctx.drawImage(e.target, 0, 0, width, height);
      canvas.toBlob(
        async blob => {
          if (!blob) {
            reject('Image could not be converted');
            return;
          }
          try {
            await copyToClipboard(blob);
            resolve(undefined);
          } catch (e) {
            reject(e);
          }
        },
        'image/png',
        1,
      );
      resolve(undefined);
    };
    imageEl.onerror = reject;
  });
}

async function copyImg(src: string) {
  const img = await fetch(src);
  const imgBlob = await img.blob();
  if (src.endsWith('.jpg') || src.endsWith('.jpeg')) {
    await convertToPng(imgBlob);
  } else if (src.endsWith('.png')) {
    await copyToClipboard(imgBlob);
  } else {
    throw 'Image format unsupported';
  }
}

async function copyToClipboard(pngBlob: Blob) {
  // @ts-ignore
  await navigator.clipboard.write([
    // @ts-ignore
    new ClipboardItem({
      [pngBlob.type]: pngBlob,
    }),
  ]);
}
