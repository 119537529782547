import React, { useEffect, useState } from 'react';
import { favoriteItem, unfavoriteItem } from '~/api/item';
import { useModals } from '~/contexts/ModalsContext';
import { useSession } from '~/contexts/SessionContext';
import {
  favoritedItem,
  unfavoritedItem,
} from '~/services/analytics/events/item';
import { RailsFacetItemMeta } from '~/typings/services/rails/facets';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';

interface UseItemFavoriteOptions {
  meta?: RailsFacetItemMeta;
  onFavorite?: () => void;
}

export default function useItemFavorite(
  item: RailsItem | RailsThumbnailItem,
  { meta, onFavorite }: UseItemFavoriteOptions = {},
) {
  const { loggedIn } = useSession();
  const modals = useModals();
  // const [dontShowCookie, setDontShowCookie] = useKnownCookie(
  //   'seen_favorites_guidance',
  // );

  const [favorited, setFavorited] = useState(!!item.favorite);
  const [favoritersCount, setFavoritersCount] = useState(item.favoriters_count);

  useEffect(() => {
    setFavorited(meta?.favorite || !!item.favorite);
    setFavoritersCount(
      // This covers a rare case where facet_items is cached and reports an outdated
      // favoriters_count. If the favoriters count is 0, but you have it favorited, it
      // shows up weird. So just hardcode it as 1
      (meta?.favorite || item.favorite) && item.favoriters_count == 0
        ? 1
        : item.favoriters_count,
    );
  }, [meta?.favorite, item.favorite, item.favoriters_count]);

  const actuallyFavorite = async () => {
    if (favorited) {
      setFavorited(false);
      setFavoritersCount(favoritersCount - 1);
      await unfavoriteItem(item.id);
      unfavoritedItem(item);
    } else {
      /*let showModal =
        !dontShowCookie && userConfiguration.data?.favorites_count === 0;
      if (showModal) {
        modals.openModal('savedToFavoritesGuidance', {
          itemId: item.id,
        });
        setDontShowCookie('1', {
          expires: 3000,
        });
      }*/

      setFavorited(true);
      setFavoritersCount(favoritersCount + 1);
      await favoriteItem(item.id);
      favoritedItem(item);

      // Refresh _after_ we've favorited the item
      // if (showModal) {
      //   userConfiguration.refetch();
      // }
    }

    onFavorite?.();
  };

  const favorite: React.MouseEventHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    if (loggedIn) {
      actuallyFavorite();
    } else {
      modals.openModal('loginRegister', {
        contextMessage: 'Log in to add this item to My Favorites',
        defaultView: 'login',
        onSuccessfulLogin: actuallyFavorite,
        src: 'item',
      });
    }
  };

  return {
    favorite,
    favorited,
    favoritersCount,
  };
}
