import { clsx } from 'clsx';
import { useMobile } from 'common-nextjs';
import Head from 'next/head';
import React from 'react';
import Image, { generateFastlyImageUrl } from '~/components/Image';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';
import { FT_FASTLY_IO } from '../../constants/featureToggles';

export function getImageUrl(
  item: RailsThumbnailItem | RailsItem,
  mobile: boolean,
) {
  let image: string;
  if (FT_FASTLY_IO) {
    if (item.primary_image) {
      image = item.primary_image.large_url;
    } else {
      image = item.images[0]?.large_url;
    }
  } else {
    if (item.primary_image) {
      if (mobile) {
        image = item.primary_image.mobile_thumb_url;
      } else {
        image = item.primary_image.thumb_url;
      }
    } else {
      if (mobile) {
        image = item.images[0]?.mobile_thumb_url;
      } else {
        image = item.images[0]?.thumb_url;
      }
    }
  }

  return image;
}

interface Props {
  className?: string;
  item: RailsThumbnailItem | RailsItem;
  position?: number;
  preload?: boolean;
  zoomContain?: boolean;
}

const ItemThumbnailImage: React.FC<Props> = ({
  className,
  item,
  position,
  preload,
  zoomContain,
}) => {
  const mobile = useMobile();
  const image = getImageUrl(item, mobile);

  const imageUrl = generateFastlyImageUrl(image, {
    height: 500,
    optimize: 'high',
  });

  return (
    <>
      {preload && (
        <Head>
          <link rel="preload" as="image" href={imageUrl} />
        </Head>
      )}

      <Image
        className={clsx(
          'transition-all duration-200',
          {
            'object-contain': zoomContain,
            'object-cover': !zoomContain,
          },
          className,
        )}
        loading={position != null && position < 2 ? 'eager' : 'lazy'}
        alt={item.state === 'draft' ? '' : item.name}
        src={imageUrl}
      />
    </>
  );
};

export default ItemThumbnailImage;
