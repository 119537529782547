import React from 'react';
import ShippingIcon from '~/components/Icons/ShippingIcon';

interface Props {
  variant?: 'contained' | 'text';
}

const ItemFreeShippingPill: React.FC<Props> = ({ variant = 'contained' }) => {
  if (variant === 'text') {
    return (
      <div className="text-xs uppercase text-green-500">Free Shipping</div>
    );
  }

  return (
    <div className="bg-mint-green-500 inline-flex flex-wrap items-center space-x-2 rounded-full px-2 py-0.5 text-xs font-semibold">
      <ShippingIcon className="min-w-0" />
      <span className="min-w-0 flex-1 truncate">Free Shipping</span>
    </div>
  );
};

export default ItemFreeShippingPill;
