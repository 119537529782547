import { RailsWebConfiguration } from '~/typings/services/rails/configuration';

const sidelineProAvailableUsers = [
  'sidelinechief',
  'eraynor2018',
  'allevaton',
  'Saint',
  'ecr2014',
  'soccerandlax7',
];

export function isSidelineProAvailable(
  config?: RailsWebConfiguration,
  username?: string,
) {
  if (sidelineProAvailableUsers.includes(username!)) {
    return true;
  }

  if (config?.sideline_pro) {
    return true;
  }

  // if (getEnv() === 'development') {
  //   return true;
  // }

  return false;
}
